import React from 'react';

import PageTemplate, {
  pageCommonPropTypes,
  renderCmsComponents,
} from './templates/PageTemplate';

const LandingPage = ({ content = {}, fpti = {}, pageInfo = {} }) => {
  const {
    components = [],
    subNav,
    globalDisclosure,
    articleStickyButton,
  } = content.pageData || {};

  return (
    <PageTemplate
      fpti={fpti}
      subNav={subNav}
      pageInfo={pageInfo}
      globalDisclosure={globalDisclosure}
      articleStickyButton={articleStickyButton}
    >
      {renderCmsComponents(components, pageInfo)}
    </PageTemplate>
  );
};

LandingPage.propTypes = pageCommonPropTypes;

export default LandingPage;
